/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-04 12:56:35
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-02 17:53:12
 * @FilePath: /js-view-newbox-h5/src/utils/request.ts
 * @Description: axios
 */
import axios from 'axios'
import { TriggerLogin } from '@/components/LoginWrapper'
import { isPc } from '@/utils/common'
import { store } from '@/store/store'
import Toast from '@/utils/toast'

type RequestMethod = 'GET' | 'POST'

interface IRequestParams {
  url: string
  method: RequestMethod
  headers?: any
  params?: any
  data?: any
  loading?: string
}

// 来自仙选后台的接口
const xianxuanAdminApis: string[] = [
  '/api/Admin/Template/info',
  '/api/Admin/Template/config',
  '/api/Admin/Template/configAll',
  '/api/Admin/Template/textToPicture',
  '/api/Admin/Plugs/changeLogoColor',
  '/api/Admin/Plugs/gaoding',
  '/api/Admin/Goods/getSpuInfo',
  '/api/Admin/Goods/saveSpuLocationInfo',
  '/api/Admin/Plugs/getOssToken',
  '/api/Admin/Template/buildUpTemplate',
  '/api/Admin/Goods/preview',
  '/api/Admin/goods/templateGoodsList',
  '/api/Admin/Template/newConfig',
  '/api/Admin/MaterialLibrary/list',
  '/api/Admin/MaterialLibrary/add',
  '/api/Admin/MaterialLibrary/moveToGlobal',
  '/api/Admin/MaterialLibrary/delete',
  '/api/Admin/Goods/getSpuTemplate',
]

axios.create({
  baseURL: '/',
  timeout: 30000,
  headers: { 'Cache-Control': 'no-cache' },
})
axios.defaults.baseURL = ''
// 后端接口协议和域名还有端口
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'
const userToken = window.localStorage.getItem('token')
// @ts-ignore
axios.defaults.headers.token = `${userToken}`
// @ts-ignore
axios.defaults.headers['Cache-Control'] = 'no-cache'

axios.interceptors.request.use(
  (config: any) => {
    const isAdmin =
      xianxuanAdminApis.indexOf(config.url || '') === -1 ? false : true
    const token = isAdmin
      ? window.localStorage.getItem('Admin_userToken')
      : window.localStorage.getItem('BY_userToken')
    if (token) {
      config.headers.token = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res) {
      if (res.code !== 200) {
        if (res.code === 410) {
          Toast.hide()
          if (isPc()) {
            const status = store.getState().global.openLoginStatus
            if (!status) {
              TriggerLogin.open(
                'login',
                `${window.location.pathname}${window.location.search}`,
                true
              )
              store.dispatch.global.clearLoginStatus()
              store.dispatch.global.changeLoginStatus(true)
            }
          } else {
            const status = store.getState().global.openLoginStatus
            if (!status) {
              TriggerLogin.open(
                'bindMobile',
                `${window.location.pathname}${window.location.search}`,
                true
              )
              store.dispatch.global.clearLoginStatus()
              store.dispatch.global.changeLoginStatus(true)
            }
            store.dispatch.global.clearLoginStatus()
          }
        } else {
          Toast.error(res.msg || 'Error', 2)
        }
        return Promise.reject(res.msg || 'Error')
      } else {
        return res
      }
    } else {
      return res
    }
  },
  (error) => {
    if (error.response.data.code === 410) {
      Toast.hide()
      if (isPc()) {
        const status = store.getState().global.openLoginStatus
        if (!status) {
          TriggerLogin.open(
            'login',
            `${window.location.pathname}${window.location.search}`,
            true
          )
          store.dispatch.global.clearLoginStatus()
          store.dispatch.global.changeLoginStatus(true)
        }
      } else {
        const status = store.getState().global.openLoginStatus
        if (!status) {
          TriggerLogin.open(
            'bindMobile',
            `${window.location.pathname}${window.location.search}`,
            true
          )
          store.dispatch.global.changeLoginStatus(true)
        }
        store.dispatch.global.clearLoginStatus()
      }
    } else {
      Toast.error(
        error.response.data.msg !== undefined && error.response.data.msg
          ? error.response.data.msg
          : 'Error',
        2
      )
    }
    return Promise.reject(error.response.data.msg || 'Error')
  }
)

// create就是创建实例
const request = <T>({
  url,
  method,
  headers,
  params = {},
  data = {},
  loading,
}: IRequestParams) => {
  // 这是post请求头设置
  return new Promise((resolve: (value: any) => void, reject) => {
    if (loading) {
      Toast.loading(loading)
    }
    // 数据请求
    switch (method) {
      case 'POST':
        axios({
          url:
            url.indexOf('?') > -1
              ? `${url}&ttt=${Date.now()}`
              : `${url}?ttt=${Date.now()}`,
          method,
          data,
          headers,
        })
          .then((res: any) => {
            if (loading) {
              Toast.hide()
            }
            resolve(res.result)
          })
          .catch((err) => {
            if (loading) {
              Toast.hide()
            }
            // reject(err)
          })
        break
      case 'GET':
        axios({
          url,
          method,
          params: {
            ...params,
            ttt: Date.now(),
          },
        })
          .then((res: any) => {
            if (loading) {
              Toast.hide()
            }
            resolve(res.result)
          })
          .catch((err) => {
            // reject(err)
            if (loading) {
              Toast.hide()
            }
          })
        break
      default:
        axios({
          url,
          method,
          params,
          headers,
        })
          .then((res: any) => {
            if (loading) {
              Toast.hide()
            }
            resolve(res.result)
          })
          .catch((err) => {
            if (loading) {
              Toast.hide()
            }
            // reject(err)
          })
        break
    }
  })
}

export default request
